/* General container for the Services page */
.services-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 1200px;
    padding: 40px;
  }
  
  /* Header section */
  .services-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .services-header h1 {
    font-size: 3rem;
    color: #2C3E50;
  }
  
  .services-header p {
    font-size: 1.2rem;
    color: #34495E;
  }
  
  /* Services overview section */
  .services-overview h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .services-overview p {
    font-size: 1.2rem;
    color: #7F8C8D;
    line-height: 1.6;
  }
  
  /* List of services section */
  .services-list {
    margin-bottom: 40px;
  }
  
  .services-list h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .service-items {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
  }
  
  .service-item {
    flex: 1 1 calc(50% - 20px);
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    color: #2C3E50;
  }
  
  .service-item p {
    font-size: 1.1rem;
    color: #7F8C8D;
    line-height: 1.6;
  }
  
  /* Why Choose Us section */
  .why-choose-us h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .why-choose-us ul {
    list-style-type: none;
    padding: 0;
  }
  
  .why-choose-us li {
    font-size: 1.2rem;
    color: #34495E;
    margin-bottom: 10px;
  }
  
  /* Contact Us section */
  .contact-us h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .contact-us ul {
    list-style-type: none;
    padding: 0;
  }
  
  .contact-us li {
    font-size: 1.2rem;
    color: #34495E;
    margin-bottom: 10px;
  }
  
  .contact-us a {
    color: #2980B9;
    text-decoration: none;
  }
  
  .contact-us a:hover {
    text-decoration: underline;
  }
  
/* General footer styling */
.footer {
    background-color: #2C3E50;
    color: #ecf0f1;
    padding: 40px 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  
  .footer-section {
    flex: 1 1 30%;
    margin: 10px;
  }
  
  .footer-section h2, .footer-section h3 {
    font-size: 1.5rem;
    color: #ecf0f1;
  }
  
  .footer-section p {
    font-size: 1.1rem;
    color: #bdc3c7;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 10px;
  }
  
  .footer-section a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    background-color: #34495E;
    padding: 10px;
  }
  
  .footer-bottom p {
    font-size: 1rem;
    margin: 0;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      text-align: left;
    }
  
    .footer-section {
      flex: 1 1 100%;
      margin: 20px 0;
    }
  }
  
/* AboutUs.css */

/* General page layout */
.about-us-container {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }
  
  h1, h2 {
    color: #2C3E50;
  }
  
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  p {
    font-size: 1.1rem;
    color: #34495E;
    line-height: 1.6;
  }
  
  /* Section headers */
  .about-header, .about-section, .mission-section, .values-section, .team-section, .contact-section {
    margin-bottom: 40px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  /* Team section styling */
  .team-members {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .team-member h3 {
    font-size: 1.2rem;
    color: #2C3E50;
    margin-top: 10px;
  }
  
  .team-member p {
    font-size: 1rem;
    color: #7F8C8D;
  }
  
  /* Contact section */
  .contact-section ul {
    padding-left: 20px;
  }
  
  .contact-section li {
    font-size: 1.2rem;
    color: #2C3E50;
  }
  
.swiper {
    width: 100%;
    height: 100%;
    background: #000;
  }
  
  .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
  }
  
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide .title {
    font-size: 41px;
    font-weight: 900;
    color:rgb(51, 51, 51);
    
  }
  
  .swiper-slide .subtitle {
    font-size: 21px;
    color:rgb(51, 51, 51);
  }
  
  .swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
    color:black;
    
  }
  .button-sm {
    padding: 10px 27px;
    font-size: 17px;
    line-height: 26px;
    border-radius: 4px;
}

.button-primary-filled {
    color: #fff;
    background-color: #1e3953;
    border-color: #1e3953;
}
.button {
    max-width: 100%;
    font-size: 19px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: 2px solid;
    font-weight: 500;
    transition: .33s ease;
    padding: 13px 29px;
    font-size: 19px;
    line-height: 24px;
    border-radius: 4px;
}
@media (min-width: 768px) {
    .reveal-sm-inline-block {
        display: inline-block !important;
    }
}




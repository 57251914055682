/* Container for Contact Information */
.contact-info-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 40px;
    max-width: 1200px;
  }
  
  .contact-info-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-info-header h1 {
    font-size: 3rem;
    color: #2C3E50;
  }
  
  .contact-info-header p {
    font-size: 1.2rem;
    color: #34495E;
  }
  
  /* Contact details section */
  .contact-details h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-bottom: 20px;
  }
  
  .contact-item {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .contact-item strong {
    color: #2980B9;
    font-weight: bold;
  }
  
  .contact-item p {
    color: #7F8C8D;
  }
  
  /* Links styling */
  a {
    color: #2980B9;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Social Media Section */
  .social-media h2 {
    font-size: 2rem;
    color: #2C3E50;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .social-media ul {
    list-style-type: none;
    padding: 0;
  }
  
  .social-media li {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .social-media a {
    color: #2980B9;
    text-decoration: none;
  }
  
  .social-media a:hover {
    text-decoration: underline;
  }
  